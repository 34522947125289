import React from 'react';
import PropTypes from 'prop-types';

function ProductThumbnail({ product }) {
  const { product_images: productImages } = product;

  if (product.product_images.length === 0) {
    return (
      <span className="component-product-thumbnail no-image">
        <span className="iconify missing-product-image" data-icon="carbon:no-image" data-inline="false" />
      </span>
    );
  }
  return (
    <span className="component-product-thumbnail">
      <img src={productImages[0].image.thumb.url} alt={`${product.part_number}`} />
    </span>
  );
}

ProductThumbnail.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductThumbnail;
