import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../ui_kit';

/**
 * Renders a Modal Dialog
 *
 * @prop modalBodyClass - An optional prop that contains a custom CSS class for the modal body
 * @prop closeModalDialog - A required prop that contains a callback that is fired whenever the modal dialog is closed
 * @prop cssClass - An optional prop that contains a custom CSS class for the modal content
 * @prop children - An required prop that contains the content to be displayed within the modal
 * @prop hideCloseButton - An optional prop that indicate to hide the close button or not
 */
function ModalDialog({ modalBodyClass, closeModalDialog, cssClass, children, hideCloseButton }) {
  useEffect(() => {
    // Check if scrollbar is on page
    if ($(document).height() > $(window).height()) {
      $('body').css('overflow-y', 'scroll');
      $('body').css('position', 'fixed');
      $('body').css('width', '100%');
    } else {
      $('body').css('overflow', 'hidden');
    }

    return () => {
      $('body').css('overflow', 'auto');
      $('body').css('position', 'initial');
    };
  }, []);

  function handleBackgroundClick(e) {
    if (e.target.classList.contains('component-modal-dialog')) {
      e.preventDefault();
      return closeModalDialog(e);
    }
  }

  function handleClose(e) {
    e.preventDefault();
    return closeModalDialog(e);
  }

  return (
    <div className="component-modal-dialog" onClick={handleBackgroundClick} role="presentation">
      <div className={`modal-dialog-body ${modalBodyClass}`}>
        {!hideCloseButton && (
          <div className="modal-dialog-actions">
            <Link onClick={handleClose} className="close-modal-dialog-link">
              <div className="close-icon" />
            </Link>
          </div>
        )}
        <div className={`modal-dialog-content ${cssClass}`}>{children}</div>
      </div>
    </div>
  );
}

export default ModalDialog;

ModalDialog.defaultProps = {
  modalBodyClass: '',
  cssClass: '',
  hideCloseButton: false,
};

ModalDialog.propTypes = {
  closeModalDialog: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  modalBodyClass: PropTypes.string,
  cssClass: PropTypes.string,
  hideCloseButton: PropTypes.bool,
};
