import React from 'react';

function Link(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a
      {...props}
      className={`component-link ${props.disabled ? 'disabled' : ''} ${props.className}`}
      onClick={props.disabled ? () => {} : props.onClick}
      href={props.disabled ? 'javascript:void(0)' : props.href}
    />
  );
}

export default Link;
