import { emptyObject } from './object_utilities';
import { iso31662ShortProvinceState } from './geography_utilities';

function toggleBooleanState(setStateFunc, stateVar) {
  setStateFunc(!stateVar);
}

function handleInputChange(setStateFunc, e) {
  setStateFunc(e.target.value);
}

function changeEyeSVGClass(revealPassword, passwordInputLabel) {
  // icons dont rerender correctly since they are SVG. Using this to add classes in password show toggle
  let eyeOpen;
  let eyeClosed;

  if (passwordInputLabel === 'old-password') {
    eyeOpen = document.getElementById('old-password-eye-open');
    eyeClosed = document.getElementById('old-password-eye-closed');
  } else if (passwordInputLabel === 'password') {
    eyeOpen = document.getElementById('password-eye-open');
    eyeClosed = document.getElementById('password-eye-closed');
  } else {
    eyeOpen = document.getElementById('confirm-password-eye-open');
    eyeClosed = document.getElementById('confirm-password-eye-closed');
  }

  if (revealPassword) {
    eyeClosed.classList.add('hide');
    eyeClosed.classList.remove('show');

    eyeOpen.classList.add('show');
    eyeOpen.classList.remove('hide');
  } else {
    eyeClosed.classList.add('show');
    eyeClosed.classList.remove('hide');

    eyeOpen.classList.add('hide');
    eyeOpen.classList.remove('show');
  }
}

function toggleRevealPassword(setRevealPassword, revealPassword, passwordInputLabel) {
  toggleBooleanState(setRevealPassword, revealPassword);

  changeEyeSVGClass(revealPassword, passwordInputLabel);
}

/**
 * dynamically creates a form and sends a request to the specified url from a form.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} method the method to use on the form
 */
function submitForm(path, params, method) {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  Object.keys(params).forEach((key) => {
    const value = params[key];

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = value;

    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);

  form.submit();
}

function validateHPPAddress(requiredAddressFields) {
  const errors = {};

  if (iso31662ShortProvinceState(requiredAddressFields.provinceState) === null) {
    errors.provinceState = 'Invalid Entry.';
  }

  if (
    !requiredAddressFields.postalZipCode.match(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i) &&
    !requiredAddressFields.postalZipCode.match(/^\d{5}(?:[-\s]\d{4})?$/i)
  ) {
    errors.postalZipCode = 'Invalid Entry.';
  }

  Object.entries(requiredAddressFields).forEach((entry) => {
    const [key, value] = entry;

    if (value.length === 0) {
      errors[`${key}`] = 'Required';
    }
  });

  if (requiredAddressFields.phone.replace(/\D/g, '').length !== 10) {
    errors.phone = 'Must be 10 digits.';
  }

  return { valid: emptyObject(errors), errors };
}

export { handleInputChange, toggleRevealPassword, toggleBooleanState, submitForm, validateHPPAddress };
