import React from 'react';
import Link from './link';

function Button(props) {
  return (
    <Link {...props} className={`component-button ${props.className}`}>
      {props.title}
    </Link>
  );
}

export default Button;
