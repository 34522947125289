import { iso31661, iso31662 } from 'iso-3166';

const englishAbrevToISO = {
  AB: ['ab', 'alta', 'alberta'],
  BC: ['bc', 'british columbia'],
  MB: ['mb', 'man', 'manitoba'],
  NB: ['nb', 'new brunswick'],
  NL: ['nl', 'newfoundland and labrador'],
  NT: ['nt', 'nwt', 'northwest territories'],
  NS: ['ns', 'nova scotia'],
  NU: ['nu', 'nvt', 'nunavut'],
  ON: ['on', 'ont', 'ontario'],
  PE: ['pe', 'pei', 'prince edward island'],
  QC: ['qc', 'que', 'quebec'],
  SK: ['sk', 'sask', 'saskatchewan'],
  YT: ['yt', 'yukon'],
};

function iso31661CountryNumber(countryName) {
  const countryObj = iso31661.find((country) => country.name.toLowerCase() === countryName.toLowerCase());
  return countryObj !== undefined ? countryObj.numeric : countryName;
}

function iso31662ShortProvinceState(provStateName) {
  let isoCode = '';

  Object.entries(englishAbrevToISO).forEach((entry) => {
    const [key, value] = entry;

    value.forEach((abrev) => {
      if (abrev === provStateName.toLowerCase().replaceAll('.', '')) {
        isoCode = key;
      }
    });
  });

  if (isoCode.length !== 0) {
    return isoCode;
  }

  const provStateObj = iso31662.find((provState) => provState.name.toLowerCase() === provStateName.toLowerCase());
  return provStateObj !== undefined ? provStateObj.code.slice(3) : null;
}

export { iso31661CountryNumber, iso31662ShortProvinceState };
