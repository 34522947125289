import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from '../ui_kit';
import { toggleBooleanState } from '../utilities/form_utilities';
import useOutsideClick from '../hooks/use_outside_click';

function MenuDropdown({ title, leftIcon, rightIcon, optionsList }) {
  const [showMenu, setShowMenu] = useState(false);

  function closeMenu() {
    if (showMenu) {
      setShowMenu(false);
    }
  }

  const { ref } = useOutsideClick(closeMenu);

  return (
    <div id="component-menu-dropdown" ref={showMenu ? ref : null}>
      <Link
        className="link-with-icon"
        onClick={(e) => {
          e.preventDefault;
          toggleBooleanState(setShowMenu, showMenu);
        }}
      >
        {leftIcon && <span className="icon iconify left-icon" data-icon={leftIcon} data-inline="false" />}
        {title}
        {rightIcon && <span className="icon iconify right-icon" data-icon={rightIcon} data-inline="false" />}
      </Link>

      {showMenu && (
        <div className="menu">
          {optionsList.map((option, index) => (
            <Link key={index} className="menu-item" href={option.value} data-method={option.dataMethod || 'GET'}>
              {option.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default MenuDropdown;

MenuDropdown.defaultProps = {
  leftIcon: null,
  rightIcon: null,
};

MenuDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  optionsList: PropTypes.array.isRequired,
};
