import React from 'react';
import PropTypes from 'prop-types';

function TextFieldWithSubmitIcon({
  placeholder,
  onFocus,
  onBlur,
  value,
  onChange,
  onKeyDown,
  submitIcon,
  onSubmit,
  onClear,
  className,
}) {
  return (
    <div className={`component-text-field-with-submit-icon ${className}`}>
      <input
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {value.length > 0 && (
        <>
          <div className="clear-container" onClick={onClear}>
            <span className="iconify" data-icon="gg:close-o" data-inline="false" />
          </div>
          <div className="divider" />
        </>
      )}
      <div className="submit-container" onClick={onSubmit}>
        <span className="iconify" data-icon={submitIcon} data-inline="false" />
      </div>
    </div>
  );
}

TextFieldWithSubmitIcon.propTypes = {
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  submitIcon: PropTypes.string,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
};

export default TextFieldWithSubmitIcon;
