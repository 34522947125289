function signedIn() {
  return window.gon && window.gon.current_user !== undefined;
}

function currentUserIs(roleNames) {
  for (let i = 0; i < roleNames.length; i += 1) {
    if (window.gon.user_signed_in && window.gon.current_user.role_names.indexOf(roleNames[i]) >= 0) return true;
  }

  return false;
}

export { signedIn, currentUserIs };
