import { useEffect, useRef } from 'react';

/**
 * Hook to register a click outside of the passed ref
 * @param {function} onOutsideClick - Callback function ran when user clicks outside of ref
 */
export default function useOutsideClick(onOutsideClick) {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref };
}
