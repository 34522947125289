import React from 'react';
import PropTypes from 'prop-types';
import { emptyObject } from '../utilities/object_utilities';

function ProductCategoryThumbnail({ category }) {
  const { image } = category;

  if (emptyObject(image) || image.thumb.url == null) {
    return (
      <span className="component-product-category-thumbnail no-image">
        <span className="iconify missing-product-category-image" data-icon="carbon:no-image" data-inline="false" />
      </span>
    );
  }
  return (
    <span className="component-product-category-thumbnail">
      <img className="product-category-image" src={image.thumb.url} alt={`${category.description}`} />
    </span>
  );
}

ProductCategoryThumbnail.propTypes = {
  category: PropTypes.object.isRequired,
};

export default ProductCategoryThumbnail;
