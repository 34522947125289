import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ModalDialog from '../src/components/modal_dialog';
import { Link } from '../src/ui_kit';
import SignInForm from '../src/components/session_forms/sign_in_form';
import SignUpForm from '../src/components/session_forms/sign_up_form';
import { toggleBooleanState } from '../src/utilities/form_utilities';
import { signedIn } from '../src/utilities/session_utilities';
import MenuDropdown from '../src/components/menu_dropdown';

function SignInOut() {
  const [showModal, setShowModal] = useState(false);
  const [displaySignIn, setDisplaySignIn] = useState(true);
  const [errorMessages, setErrorMessages] = useState({});
  const [userSignedIn, setUserSignedIn] = useState(signedIn());

  function signInUser(email, password, rememberMe) {
    $.ajax({
      url: '/users/sign_in',
      method: 'POST',
      data: {
        user: {
          email,
          password,
          remember_me: rememberMe ? 1 : 0,
        },
      },
      success: (response) => {
        window.location.reload();
      },
      error: (response) => {
        const errorsResponse = JSON.parse(response.responseText);
        if (errorsResponse !== {}) {
          setErrorMessages(errorsResponse.errors);
        }
      },
    });
  }

  function signUpUser(userInfo) {
    const { name, email, password, passwordConfirmation } = userInfo;
    $.ajax({
      url: '/users',
      method: 'POST',
      data: {
        user: {
          name,
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
      },
      success: (response) => {
        window.location.reload();
      },
      error: (response) => {
        const errorsResponse = JSON.parse(response.responseText);
        if (errorsResponse !== {}) {
          setErrorMessages(errorsResponse.errors);
        }
      },
    });
  }

  function handleFormTypeChange(e) {
    e.preventDefault();
    setErrorMessages({});
    toggleBooleanState(setDisplaySignIn, displaySignIn);
  }

  function accountSettingsList() {
    return [
      { label: 'Orders History', value: '/orders' },
      { label: 'Account Settings', value: `/users/${window.gon.current_user.id}` },
      { label: 'Log Out', value: '/users/sign_out', dataMethod: 'delete' },
    ];
  }

  useEffect(() => {
    window.EventSystem.subscribe('modal-open.sign_up', function () {
      setShowModal(true);
      setDisplaySignIn(false);
    });

    window.EventSystem.subscribe('account-deleted.reload', function () {
      setUserSignedIn(false);
    });
  }, []);

  return (
    <div id="component-sign-in-out">
      {userSignedIn ? (
        <MenuDropdown
          title="Account"
          leftIcon="bx:bxs-user"
          rightIcon="akar-icons:chevron-down"
          optionsList={accountSettingsList()}
        />
      ) : (
        <Link
          className="link-with-icon"
          onClick={(e) => {
            e.preventDefault();
            setShowModal(true);
          }}
        >
          <span className="icon iconify left-icon" data-icon="bx:bxs-user" data-inline="false" />
          Sign in
        </Link>
      )}

      {showModal && (
        <ModalDialog
          closeModalDialog={() => {
            setDisplaySignIn(true);
            setShowModal(false);
          }}
          hideCloseButton
        >
          {displaySignIn ? (
            <SignInForm
              signInUser={signInUser}
              handleSignUpFormChange={(e) => {
                handleFormTypeChange(e);
              }}
              errorMessages={errorMessages}
            />
          ) : (
            <SignUpForm
              signUpUser={signUpUser}
              handleSignInFormChange={(e) => {
                handleFormTypeChange(e);
              }}
              errorMessages={errorMessages}
            />
          )}
        </ModalDialog>
      )}
    </div>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('sign-in-out-mount-point');

  if (element) {
    ReactDOM.render(<SignInOut />, element);
  }
});
