import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { handleInputChange, toggleRevealPassword, toggleBooleanState } from '../../utilities/form_utilities';
import { Button, Link } from '../../ui_kit';

function SignInForm({ signInUser, handleSignUpFormChange, errorMessages }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [revealPassword, setRevealPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  function handleSignInClicked(e) {
    e.preventDefault();

    signInUser(email, password, rememberMe);
  }

  return (
    <div className="component-sign-in-form">
      <div className="standard-form">
        <h2>Sign In</h2>
        <p className="form-info">Hello, please sign in to access your cart.</p>

        <label className="email" htmlFor="email">
          <p>Email</p>
          <div className="input-container">
            <input
              id="email"
              type="text"
              value={email}
              tabIndex={0}
              onChange={(e) => {
                handleInputChange(setEmail, e);
              }}
            />
          </div>
        </label>

        <label className="password" htmlFor="password">
          <div className="password-label">
            <p>Password</p>
            <Link className="regular-text" href="/users/password/new">
              Forgot Password?
            </Link>
          </div>
          <div className="input-container password">
            <input
              id="password"
              type={revealPassword ? 'text' : 'password'}
              value={password}
              tabIndex={0}
              onChange={(e) => {
                handleInputChange(setPassword, e);
              }}
              className={errorMessages.password && 'errors'}
            />
            <div
              role="button"
              tabIndex={-1}
              className="eye-selector"
              onClick={() => {
                toggleRevealPassword(setRevealPassword, revealPassword, 'password');
              }}
              onKeyDown={() => {
                toggleRevealPassword(setRevealPassword, revealPassword, 'password');
              }}
            >
              <span id="password-eye-closed" className="icon iconify password-eye closed hide" data-icon="mdi-light:eye-off" data-inline="true" data-interception="off" />
              <span id="password-eye-open" className="icon iconify password-eye open show" data-icon="mdi-light:eye" data-inline="true" data-interception="off" />
            </div>
          </div>
          {errorMessages.password && <p className="input-errors">Password {errorMessages.password}.</p>}
        </label>

        <label className="remember-me checkbox-label" htmlFor="remember-me">
          <div className="input-container custom-checkbox">
            <input
              id="remember-me"
              type="checkbox"
              value={rememberMe}
              tabIndex={0}
              onChange={() => {
                toggleBooleanState(setRememberMe, rememberMe);
              }}
            />
            <span className="custom-checkbox-span">{rememberMe && <span className="checked" />}</span>

            <p>Remember Me</p>
          </div>
        </label>

        <Button
          className="submit-button small-heading dark"
          onClick={handleSignInClicked}
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleSignInClicked(e);
          }}
          title="Sign In"
          tabIndex={0}
        />

        <div className="sign-up-container">
          <p>
            New Here?{' '}
            <span>
              <Link className="sign-up-link" onClick={handleSignUpFormChange} tabIndex={0}>
                Sign Up
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

SignInForm.propTypes = {
  signInUser: PropTypes.func.isRequired,
  handleSignUpFormChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.object.isRequired,
};

export default SignInForm;
